import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Integrations",
  "sort": 1,
  "title": "Expo (React Native)",
  "subtitle": "Accept Danish MitID, Swedish BankID, Norwegian BankID and more eID logins in your Expo (React Native) app with @criipto/verify-expo."
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Read the `}<a parentName="p" {...{
        "href": "https://github.com/criipto/criipto-verify-expo"
      }}>{`full documentation`}</a>{` or `}<a parentName="p" {...{
        "href": "https://github.com/criipto/criipto-verify-expo/tree/master/example"
      }}>{`download a sample application from GitHub`}</a>{`.`}</p>
    <h2>{`Register Your Application in Criipto Verify`}</h2>
    <p>{`After you signed up for Criipto Verify, you must `}<a parentName="p" {...{
        "href": "https://dashboard.criipto.com/applications/add"
      }}>{`register an application`}</a>{` before you can try logging in with any eID.`}</p>
    <p>{`Once your application is registered, the dashboard will contain the necessary details to configure your application to communicate with Criipto Verify.
You will need the following information from the General tab of your application settings:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Client ID`}</em>{` to identify your application to Criipto Verify.`}</li>
      <li parentName="ul"><em parentName="li">{`Domain`}</em>{` on which you will be communicating with Criipto Verify.`}</li>
    </ul>
    <h3>{`Register callback URLs`}</h3>
    <p>{`Before you can start sending authentication requests to Criipto Verify, you need to register the URLs on which you want to receive the returned `}<em parentName="p">{`JSON Web Token`}</em>{`, `}<a parentName="p" {...{
        "href": "verify/getting-started/token-contents/"
      }}>{`JWT`}</a>{`.`}</p>
    <p>{`The callback URL of your application is the URL where Criipto Verify will redirect to after the user has authenticated in order for the OpenID Connect middleware to complete the authentication process.`}</p>
    <p>{`You will need to add this URL to the list of allowed URLs for your application.`}</p>
    <p>{`For example, if you are developing your application in Expo Go, the callback URL may look like this: `}<inlineCode parentName="p">{`exp://127.0.0.1:8081`}</inlineCode>{`. Or if you've setup a custom URL scheme, it may look like `}<inlineCode parentName="p">{`yourscheme://auth/callback`}</inlineCode>{`.`}</p>
    <p>{`When you deploy your application to a different URL, you will also need to ensure you add that URL to the Callback URLs field in your application settings.`}</p>
    <Highlight icon="info" mdxType="Highlight">
      <p>{`If you wish to support `}<a parentName="p" {...{
          "href": "#app-switch-support"
        }}>{`app switch with Danish MitID`}</a>{`, you must use `}<a href="https://docs.expo.dev/guides/deep-linking/" target="_blank">{`universal/app links`}</a>{`.`}</p>
    </Highlight>
    <h2>{`Install SDK`}</h2>
    <p>{`Using `}<a parentName="p" {...{
        "href": "https://npmjs.org/"
      }}>{`npm`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install @criipto/verify-expo
`}</code></pre>
    <h2>{`Initialize SDK with your application details`}</h2>
    <p>{`Setup the Criipto Verify SDK by wrapping your application in `}<inlineCode parentName="p">{`CriiptoVerifyProvider`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// src/App.jsx
import { View } from 'react-native';
import LoginScreen from './LoginScreen.jsx';
import { CriiptoVerifyProvider, useCriiptoVerify } from '@criipto/verify-expo';

export default function App() {
  return (
    <CriiptoVerifyProvider
      domain="{{YOUR_CRIIPTO_DOMAIN}}"
      clientID="{{YOUR_CLIENT_ID}}"
    >
      <View>
        <LoginScreen />
        ...
      </View>
    </CriiptoVerifyProvider>
  );
}
`}</code></pre>
    <h3>{`Use the useCriiptoVerify() custom hook`}</h3>
    <p>{`You can now utilize the `}<inlineCode parentName="p">{`useCriiptoVerify()`}</inlineCode>{` custom hook to handle the authentication flow in your application.
`}<inlineCode parentName="p">{`useCriiptoVerify()`}</inlineCode>{` provides access to:`}</p>
    <ul>
      <li parentName="ul">{`the `}<inlineCode parentName="li">{`login`}</inlineCode>{` method, which initiates the authentication process. It requires two arguments: `}<a parentName="li" {...{
          "href": "/verify/guides/authorize-url-builder/#auth-methods--acr-values"
        }}><inlineCode parentName="a">{`acrValues`}</inlineCode></a>{` to define the eIDs you want to use, and `}<inlineCode parentName="li">{`redirectUri`}</inlineCode>{` for the `}<a parentName="li" {...{
          "href": "#register-callback-urls"
        }}>{`callback URL`}</a>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`claims`}</inlineCode>{` variable that allows to retrieve information about the user's `}<a parentName="li" {...{
          "href": "/verify/getting-started/token-contents/"
        }}>{`JWT claims`}</a>{` once they are available.`}</li>
      <li parentName="ul">{`the `}<inlineCode parentName="li">{`logout`}</inlineCode>{` method.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`error`}</inlineCode>{` variable for any potential authentication errors.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// src/LoginScreen.jsx
export default function LoginScreen() {
  const { login, claims, logout, error } = useCriiptoVerify();

  // Your function to handle the authentication process
  const handlePress = async (acrValues) => {
    try {
      // Call the login function with necessary parameters
      const redirectUri = Linking.createURL('/auth/callback');
      const result = await login(acrValues, redirectUri);
      console.log(result);
      // Do something with the authentication result
    } catch (error) {
      // Handle errors that may occur during authentication
      console.error('Authentication Error:', error);
    }
  };
  ...
}
`}</code></pre>
    <h3>{`Implement the UI components`}</h3>
    <p>{`You can now implement the UI components (buttons, links, etc.) to enable your users to log in with the eID of your choice.
In the sample code below we used buttons, but you can choose the appropriate UI elements based on your application's requirements.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`export default function LoginScreen() {
  const { login, claims, logout, error } = useCriiptoVerify();

  const handlePress = async (acrValues) => {
    try {
      const redirectUri = Linking.createURL('/auth/callback');
      const result = await login(acrValues, redirectUri);
      console.log(result);
    } catch (error) {
      console.error('Authentication Error:', error);
    }
  };

  return (
    <>
      <Button
        onPress={() => handlePress('urn:grn:authn:dk:mitid:substantial')}
        title="Login with Danish MitID"
      />
      <Button
        onPress={() => handlePress('urn:grn:authn:se:bankid:same-device')}
        title="Login with Swedish BankID"
      />
      <Button
        onPress={() => handlePress('urn:grn:authn:fi:bank-id')}
        title="Login with Finnish BankID"
      />
      <Button
        onPress={() => handlePress('urn:grn:authn:no:bankid:substantial')}
        title="Login with Norwegian BankID"
      />

      {error ? <Text>An error occurred: {error.toString()}</Text> : null}

      {claims ? <Text>{JSON.stringify(claims, null, 2)}</Text> : null}
    </>
  );
}
`}</code></pre>
    <h2>{`App switch support`}</h2>
    <p><inlineCode parentName="p">{`@criipto/verify-expo`}</inlineCode>{` supports app switching for `}<a parentName="p" {...{
        "href": "/verify/guides/appswitch/#swedish-bankid"
      }}>{`Swedish BankID`}</a>{` and `}<a parentName="p" {...{
        "href": "/verify/guides/appswitch/#danish-mitid"
      }}>{`Danish MitID`}</a>{`.`}</p>
    <h3>{`Danish MitID + Android`}</h3>
    <p>{`Switchback from the Danish MitID mobile application will only work if you are using a universal link / app link as your `}<inlineCode parentName="p">{`redirect_uri`}</inlineCode>{`.`}</p>
    <a href="https://docs.expo.dev/guides/deep-linking/" target="_blank">
  Guide to Expo universal links.
    </a>
    <p>{`You can enable switchback on Android by adding the following to your `}<inlineCode parentName="p">{`app.json`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`"plugins": [
  ["@criipto/verify-expo", {
    "androidAppLinks": [
      "https://..."
    ]
  }]
]
`}</code></pre>
    <h4>{`Expo Go`}</h4>
    <p>{`Danish MitID on Android will not work with Expo Go due to the use of `}<inlineCode parentName="p">{`createTask: false`}</inlineCode>{`. You must use a build to test, for instance with `}<inlineCode parentName="p">{`npx expo run:android`}</inlineCode></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      